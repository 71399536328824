exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legacy-app-steps-actual-refactoring-tsx": () => import("./../../../src/pages/legacy-app-steps/actual-refactoring.tsx" /* webpackChunkName: "component---src-pages-legacy-app-steps-actual-refactoring-tsx" */),
  "component---src-pages-legacy-app-steps-index-tsx": () => import("./../../../src/pages/legacy-app-steps/index.tsx" /* webpackChunkName: "component---src-pages-legacy-app-steps-index-tsx" */),
  "component---src-pages-legacy-app-steps-setup-linter-tsx": () => import("./../../../src/pages/legacy-app-steps/setup-linter.tsx" /* webpackChunkName: "component---src-pages-legacy-app-steps-setup-linter-tsx" */),
  "component---src-pages-legacy-app-steps-testing-tsx": () => import("./../../../src/pages/legacy-app-steps/testing.tsx" /* webpackChunkName: "component---src-pages-legacy-app-steps-testing-tsx" */),
  "component---src-pages-setup-tsx": () => import("./../../../src/pages/setup.tsx" /* webpackChunkName: "component---src-pages-setup-tsx" */)
}

